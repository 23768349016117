//
//
//
//

export default {
    name: "MessageText",
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    computed: {
        isDataType() {
            return this.message.dataType === declarations.msgDataTypes.MSG_DATA_TYPE_DATA
        },
        text() {
            if (!this.isDataType) return this.getHtml(this.message.data)
           switch (this.message.data.type) {
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                   return this.$t('image')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                   return this.$t('video')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
                   return this.$t('file')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                   return this.$t('audio')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
                   return this.$t('contact')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
                   return this.$t('mainPage.location')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
                   return this.$t('channel')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
                   return this.$t('publication')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
                   return this.$t('poll')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
                   return this.$t('chat.abonentIsAvailable')
               case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
                   return this.getHtml(this.message.data.text)
           }
        },
    },
    methods: {
        getHtml(text) {
            let sanitized_text = text.replace(/</g,'&lt;').replace(/>/g,'&gt;')
            return this.wrapEmoji(this.lookForRefs(sanitized_text))
        }
    },

}
